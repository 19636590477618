import React, { useEffect, useRef, memo, useMemo, useContext, useCallback } from 'react';
import BottomTabNavigator from './BottomTabNavigator';
import { DrawerActions } from '@react-navigation/native';
import { createDrawerNavigator } from '@react-navigation/drawer';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { View, StyleSheet, ScrollView, Pressable } from 'react-native';
import { logoutApi } from '../actions/logout';
import { getKeywordsApi } from '../actions/getKeywords';
import { MAIN_COLOR, GRAY_LINE_COLOR, SECONDARY_COLOR, GRAY_LIGHT, DRAWER_WIDTH, GRAY_TEXT_PALE } from '../constants';
import { selectAccount, selectRemainingProfileElementsToFill } from '../selectors';
import { Ionicons, Entypo } from '@expo/vector-icons';
import DrawerNavItem from '../components/DrawerNavItem';
import DrawerMainNav from './DrawerMainNav';
import InterReg from '../components/InterReg';
import Avatar from '../components/Avatar';
import MenuAdd from '../components/MenuAdd';
import Button from '../components/Button';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import MontserratBold from '../components/MontserratBold';
import * as Notifications from 'expo-notifications';
import AppSettingsContext from '../context/AppSettingsContext';
import { setExpoTokenApi } from '../actions/setExpoToken';
import { isNative, isLargeScreen, getMetrics } from '../utils';
import handlePushNotifications, { registerForPushNotificationsAsync } from '../utils/handlePushNotifications';
import { useGetDataOnNavFocus, useUnreadNotifs } from '../hooks';
import { deleteToken } from '../utils/handleToken';
import { emailSmtpError } from '../utils/alerts';

const Drawer = createDrawerNavigator();

function getDrawerNavItems(navigation, { followRequestsCount, unreadMessagesCount, remainingProfileElementsToFill, isPrivate }) {

    const items = [
        {
            label: 'sidebar.navItems.messages',
            badge: unreadMessagesCount,
            icon: () => <Ionicons name='mail' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Discussions', navigation)
        },
        {
            label: 'sidebar.navItems.profile',
            badge: remainingProfileElementsToFill,
            icon: () => <Ionicons name='person' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Profile', navigation)
        },
        {
            label: 'sidebar.navItems.settings',
            icon: () => <Ionicons name='settings' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Settings', navigation)
        },
        {
            label: 'sidebar.navItems.share',
            icon: () => <Ionicons name='share' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Share', navigation)
        },
        {
            label: 'sidebar.navItems.contact',
            icon: () => <Ionicons name='megaphone' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('Contact', navigation)
        }
    ]
    if(isPrivate) {
        items.splice(2,0, {
            label: 'sidebar.navItems.followRequests',
            badge: followRequestsCount,
            icon: () => <Ionicons name='notifications' size={18} color={SECONDARY_COLOR} />,
            onPress: () => handleDrawerNavItemPress('FollowRequests', navigation)
        })
    }
    return items;
}


function handleDrawerNavItemPress(screen, navigation) {
    navigation.dispatch(DrawerActions.closeDrawer());
    navigation.navigate('Modal', { screen })
}

const CustomDrawerContent = memo(({ navigation }) => {
    const {t} = useContext(AppSettingsContext);
    const account = useSelector(selectAccount);
    const remainingProfileElementsToFill = useSelector(selectRemainingProfileElementsToFill, shallowEqual);
    const insets = useSafeAreaInsets();
    const dispatch = useDispatch();
    const nickname = account?.nickname ?? '';
    const avatar = account?.avatar ?? null;
    const { unreadFollowRequests, unreadMessagesCount } = useUnreadNotifs();
    const drawerNavItems = useMemo(() => getDrawerNavItems(
        navigation, { 
            followRequestsCount: unreadFollowRequests.length,
            unreadMessagesCount, 
            remainingProfileElementsToFill,
            isPrivate: account?.isPrivate 
    }), [remainingProfileElementsToFill, unreadMessagesCount, unreadFollowRequests, account?.isPrivate]);
    const handleLogoutPress = () => {
        navigation.closeDrawer();
        dispatch(logoutApi()).then(async (json) => {
            if(json?.success && isNative()) await deleteToken();
        })
    }
    const Icon = <Entypo name='plus' size={20} color={ '#fff' } style={{marginLeft: -15, marginRight: 6 }} />;
    const renderAddButton = useCallback(() => (
        <Button 
            Icon={Icon}
            text={ t('sidebar.addButton')}
            textStyle={{ fontSize: 14 }} 
            buttonStyle={{marginBottom: 0, height: 35 }} />
    ), []);
    const { channel, revisionId } = getMetrics();
    useEffect(() => {
        if(navigation && account && account.emailSmtpErrorCode) {
            emailSmtpError(() => navigation.navigate('Modal', { screen: 'UpdateProfile' }), dispatch, { email: account.email}), t;
        }
    }, [account, navigation])
    return (
        <View style={{ flex: 1, backgroundColor: isLargeScreen() ? GRAY_LIGHT : '#fff' }}>
            <View style={[styles.header, { paddingTop: 40 + insets.top }]}>
                <Pressable style={styles.headerPressable} onPress={ () => handleDrawerNavItemPress('Profile', navigation)}>
                    <Avatar avatar={avatar} blurHash={account?.blurHash}avatarSize={45} avatarStyle={{ borderColor: '#fff', borderWidth: 2 }} />
                    <View style={{ marginLeft: 10, flex: 1 }}>
                        <InterReg style={styles.welcome} text={ t('sidebar.welcome')} />
                        <MontserratBold numberOfLines={1} style={styles.nickname} text={ nickname } />
                    </View>
                </Pressable>
            </View>
            { isLargeScreen() && 
                <View style={styles.addButton}>
                    <MenuAdd 
                        TriggerButton={renderAddButton} 
                        navigation={navigation} />
                </View> 
            }
            <ScrollView contentContainerStyle={{ paddingTop: isLargeScreen() ? 8 : 50 }}>
                { isLargeScreen() && <DrawerMainNav navigation={navigation} /> }
                { drawerNavItems.map((props, i) => (
                    <DrawerNavItem 
                        key={i} 
                        style={[
                            i == 0 ? styles.borderTop : {},
                            { paddingLeft: isLargeScreen() ? 33 : 40 }
                        ]} {...props} />
                    ))
                }
            </ScrollView>
            <View style={[styles.footer, { paddingBottom: 25 + insets.bottom }]}>
                <DrawerNavItem 
                    label='sidebar.logout' 
                    icon={ () => <Ionicons name='exit' size={17} color="#666" /> }
                    labelStyle={{ color: '#666' }}
                    style={{ borderBottomWidth: 0, justifyContent: 'center', paddingLeft: 0 }}
                    onPress={handleLogoutPress} 
                />
                <InterReg style={{ color: GRAY_TEXT_PALE, fontSize: 12, textAlign: 'center' }} text={ t('sidebar.credit') } />
                <InterReg 
                    text={ `v.${revisionId} ${channel}` } 
                    style={{ textAlign: 'center', color: GRAY_TEXT_PALE, marginTop: 15, fontSize: 10 }} />
            </View>
        </View>
    );
})

function DrawerNavigator({ navigation }) {
    const notificationListener = useRef();
    const responseListener = useRef();
    const dispatch = useDispatch();
    useGetDataOnNavFocus(navigation, () => dispatch(getKeywordsApi()));
    const renderDrawerContent = useCallback(({ navigation }) => <CustomDrawerContent navigation={navigation} />, []);
    useEffect(() => {
        if(isNative()) {
            registerForPushNotificationsAsync().then(token => { if(token !== undefined ) { dispatch(setExpoTokenApi(token)) } });
            notificationListener.current = Notifications.addNotificationReceivedListener(notification => {
                handlePushNotifications({ notification, dispatch, navigation, interacted: false });
            });
        
            responseListener.current = Notifications.addNotificationResponseReceivedListener(response => {
                const { notification, actionIdentifier } = response;
                if(actionIdentifier === Notifications.DEFAULT_ACTION_IDENTIFIER) {
                    handlePushNotifications({ notification, dispatch, navigation, interacted: true });
                }
            });
        }
    
        return () => {
            if(isNative()) {
                Notifications.removeNotificationSubscription(notificationListener.current);
                Notifications.removeNotificationSubscription(responseListener.current);
            }
        };
    }, []);
    return (
        <Drawer.Navigator
            drawerContent={renderDrawerContent} 
            screenOptions={{ 
                headerShown: false,
                drawerType: isLargeScreen() ? 'permanent' : 'front',
                drawerStyle: { width: DRAWER_WIDTH }
            }}>
            <Drawer.Screen name="Tabs" component={BottomTabNavigator} />
        </Drawer.Navigator>
    );
}
export default memo(DrawerNavigator);

const styles = StyleSheet.create({
    header: { 
        backgroundColor: MAIN_COLOR,
        borderBottomColor: 'rgba(255,255,255,0.3)',
        borderBottomWidth: 4
    },
    headerPressable: {
        paddingBottom: 13,
        paddingLeft: 14,
        flexDirection: 'row', 
        alignItems: 'center'
    },
    welcome: {
        color: '#fff', 
        fontSize: 14,
        marginBottom: 2
    },
    nickname: {
        color: '#fff',
        fontSize: 15,
        flex: 1,
        paddingRight: 8
    },
    addButton: {
        padding: 20,
        paddingHorizontal: 23,
        borderBottomWidth: StyleSheet.hairlineWidth,
        borderBottomColor: GRAY_LINE_COLOR
    },
    footer: {
        marginTop: 'auto',
        paddingHorizontal: 15,
        borderTopWidth: 1,
        borderTopColor: GRAY_LINE_COLOR,
        backgroundColor: GRAY_LIGHT
    },
    borderTop: {
        borderTopWidth: StyleSheet.hairlineWidth,
        borderTopColor: GRAY_LINE_COLOR
    }
})