import * as Linking from 'expo-linking';
import { getStateFromPath } from '@react-navigation/native';
import { store } from '../store';
import { setInitialLoadPath } from '../actions';
import { PUBLIC_ROUTE_NAMES, APP_ROOT_PATH, MODALS_OPENABLE_BY_ACTION_URL_PARAM, DOMAIN } from '../constants';
import { getCanonicalPath, getRouteDetailsFromStateFromPath, isNative } from '../utils';

export default {
    prefixes: [Linking.makeUrl('/')],
    config: {
        screens: {
            Auth: {
                screens: {
                    Welcome: `${APP_ROOT_PATH}`,
                    SignIn: `${APP_ROOT_PATH}signin`,
                    SignUp: `${APP_ROOT_PATH}signup`,
                    ResetPassword: `${APP_ROOT_PATH}reset-password`,
                }
            },
            Root: {
                screens: {
                    Tabs: {
                        screens: {
                            HomeTab: {
                                screens: {
                                    Home: {
                                        screens: {
                                            HomeTabs: {
                                                screens: {
                                                    Activity: `${APP_ROOT_PATH}home/activity`,
                                                    Community: `${APP_ROOT_PATH}home/community`
                                                }
                                            },
                                        },
                                    },
                                    Book: `${APP_ROOT_PATH}home/book/:bookId`,
                                    User: `${APP_ROOT_PATH}home/user/:userId`,
                                    Followers: `${APP_ROOT_PATH}home/user/:userId/followers`,
                                    Following: `${APP_ROOT_PATH}home/user/:userId/following`,
                                    Group: `${APP_ROOT_PATH}home/group/:groupSlug/:groupId`,
                                    GroupCollection: `${APP_ROOT_PATH}home/group/group/:groupSlug/:groupId/collection/:groupCollectionId`,
                                    Shelf: `${APP_ROOT_PATH}home/user/shelf/:shelfId`,
                                    List: `${APP_ROOT_PATH}home/user/list/:listId`,
                                }
                            },
                            LibraryTab: {
                                screens: {
                                    Library: {
                                        screens: {
                                            LibraryTabs: {
                                                screens: {
                                                    BooksTabs: {
                                                        screens: {
                                                            Books: `${APP_ROOT_PATH}library/books`,
                                                            Shelves: `${APP_ROOT_PATH}library/shelves`,
                                                            Lists: `${APP_ROOT_PATH}library/lists`
                                                        }
                                                    },
                                                    Favourites: `${APP_ROOT_PATH}library/favourites`
                                                }
                                            }
                                        }
                                    },
                                    User: `${APP_ROOT_PATH}library/user/:userId`,
                                    Followers: `${APP_ROOT_PATH}library/user/:userId/followers`,
                                    Following: `${APP_ROOT_PATH}library/user/:userId/following`,
                                    Book: `${APP_ROOT_PATH}library/book/:bookId`,
                                    Shelf: `${APP_ROOT_PATH}library/shelf/:shelfId`,
                                    List: `${APP_ROOT_PATH}library/list/:listId`,
                                    
                                }
                            },
                            Add: 'Add',
                            LendsTab: {
                                screens: {
                                    Lends: {
                                        screens: {
                                            LendsTabs: {
                                                screens: {
                                                    Borrowing: `${APP_ROOT_PATH}exchanges/borrowing`,
                                                    Lending: `${APP_ROOT_PATH}exchanges/lending`,
                                                    BookRequests: `${APP_ROOT_PATH}exchanges/book-requests`
                                                }
                                            }
                                        }
                                    },
                                    List: `${APP_ROOT_PATH}exchanges/user/list/:listId`,
                                    Shelf: `${APP_ROOT_PATH}exchanges/user/shelf/:shelfId`,
                                    User: `${APP_ROOT_PATH}exchanges/user/:userId`,
                                    Followers: `${APP_ROOT_PATH}exchanges/user/:userId/followers`,
                                    Following: `${APP_ROOT_PATH}exchanges/user/:userId/following`,
                                    Book: `${APP_ROOT_PATH}exchanges/book/:bookId`,
                                },
                            },
                            GroupsTab: {
                                screens: {
                                    Groups: {
                                        screens: {
                                            GroupsTabs: {
                                                screens: {
                                                    PublicGroups: `${APP_ROOT_PATH}groups/public`,
                                                    MyGroups: `${APP_ROOT_PATH}groups/mine`,
                                                    GroupRequests: `${APP_ROOT_PATH}groups/requests`
                                                }
                                            }
                                        }
                                    },
                                    List: `${APP_ROOT_PATH}groups/user/list/:listId`,
                                    Shelf: `${APP_ROOT_PATH}groups/user/shelf/:shelfId`,
                                    User: `${APP_ROOT_PATH}groups/user/:userId`,
                                    Followers: `${APP_ROOT_PATH}groups/user/:userId/followers`,
                                    Following: `${APP_ROOT_PATH}groups/user/:userId/following`,
                                    Book: `${APP_ROOT_PATH}groups/book/:bookId`,
                                    Group: `${APP_ROOT_PATH}groups/group/:groupSlug/:groupId`,
                                    GroupBook: `${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/book/:bookId`,
                                    GroupMembers: `${APP_ROOT_PATH}group/:groupId/members`,
                                    GroupCollection: `${APP_ROOT_PATH}groups/group/:groupSlug/:groupId/collection/:groupCollectionId`
                                },
                            },
                        }
                    }
                }
            },
            Modal: {
                screens: {
                    Profile: `${APP_ROOT_PATH}profile`,
                    UpdateProfile: `${APP_ROOT_PATH}update-profile`,
                    Settings: `${APP_ROOT_PATH}settings`,
                    Account: `${APP_ROOT_PATH}account`,
                    Terms: `${APP_ROOT_PATH}terms`,
                    ChangePassword: `${APP_ROOT_PATH}change-password`,
                    UpdateBook: `${APP_ROOT_PATH}update-book/:bookId`,
                    AddShelf: `${APP_ROOT_PATH}add-shelf`,
                    UpdateShelf: `${APP_ROOT_PATH}update-shelf/:shelfId`,
                    AddBook: `${APP_ROOT_PATH}add-book`,
                    AddBookReview: `${APP_ROOT_PATH}review-book/:bookId`,
                    AddBookSearch: `${APP_ROOT_PATH}add-book-search`,
                    BookProviderResult: `${APP_ROOT_PATH}add-book-search/:index`,
                    AddList: `${APP_ROOT_PATH}add-list`,
                    UpdateList: `${APP_ROOT_PATH}update-list/:listId`,
                    TakePhoto: `${APP_ROOT_PATH}take-photo`,
                    Lend: `${APP_ROOT_PATH}lend/:bookId`,
                    BookInLists: `${APP_ROOT_PATH}add-book-to-lists/:bookId`,
                    FollowRequests: `${APP_ROOT_PATH}follow-requests`,
                    Discussions: `${APP_ROOT_PATH}discussions`,
                    Discussion: `${APP_ROOT_PATH}discussion/:discussionId`,
                    Contact: `${APP_ROOT_PATH}contact`,
                    Share: `${APP_ROOT_PATH}share`,
                    ChooseGroupProduct: `${APP_ROOT_PATH}choose-group-type`,
                    GroupProduct: `${APP_ROOT_PATH}group-product/:groupProductId`,
                    GroupProductPro: `${APP_ROOT_PATH}group-product-pro/:groupProductId`,
                    AddGroup: `${APP_ROOT_PATH}add-group`,
                    PrePaymentGroupProduct: `${APP_ROOT_PATH}group-added-recap/:groupId/:groupProductId`,
                    UpdateGroup: `${APP_ROOT_PATH}update-group/:groupId`,
                    BooksInGroup: `${APP_ROOT_PATH}add-remove-books-from-group/:groupId`,
                    UpdateShelfShareBooksGroup: `${APP_ROOT_PATH}update-shelf-share-books/:shelfId/group/:groupId`,
                    InviteToJoinGroup: `${APP_ROOT_PATH}invite-to-join-group/:groupId`,
                    Search: `${APP_ROOT_PATH}search`,
                    Book: `${APP_ROOT_PATH}modal/book/:bookId`,
                    User: `${APP_ROOT_PATH}modal/user/:userId`,
                    Group: `${APP_ROOT_PATH}modal/group/:groupSlug/:groupId`,
                    GroupBook: `${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/book/:bookId`,
                    GroupMembers: `${APP_ROOT_PATH}modal/group/:groupId/members`,
                    GroupShare: `${APP_ROOT_PATH}modal/group/share/:groupId`,
                    Followers: `${APP_ROOT_PATH}modal/user/:userId/followers`,
                    Following: `${APP_ROOT_PATH}modal/user/:userId/following`,
                    Shelf: `${APP_ROOT_PATH}modal/user/shelf/:shelfId`,
                    List: `${APP_ROOT_PATH}modal/user/list/:listId`,
                    InviteUsers: `${APP_ROOT_PATH}invite-users`,
                    AddGroupCollection: `${APP_ROOT_PATH}modal/add-group-collection/:groupId`,
                    UpdateGroupCollection: `${APP_ROOT_PATH}modal/update-group-collection/:groupCollectionId`,
                    BooksInGroupCollection: `${APP_ROOT_PATH}modal/add-remove-books-from-group-collection/:groupCollectionId`,
                    GroupCollection: `${APP_ROOT_PATH}modal/group/:groupSlug/:groupId/collection/:groupCollectionId`,
                    Subscriptions: `${APP_ROOT_PATH}account/subscriptions`,
                    GroupProductCheckoutSuccess: `${APP_ROOT_PATH}modal/checkout-success/group/:groupId`,
                    PremiumGroupOnly: `${APP_ROOT_PATH}premium-group-only/:groupId/:feature`,
                    AddUserKeyword: `${APP_ROOT_PATH}add-keyword`,
                    UpdateUserKeyword: `${APP_ROOT_PATH}update-keyword/:userKeywordId`,
                    ChooseAccountProduct: `${APP_ROOT_PATH}choose-account-type`,
                    AccountProduct: `${APP_ROOT_PATH}account-product/:accountProductId`,
                    PrePaymentAccountProduct: `${APP_ROOT_PATH}account-offer-recap/:accountProductId/:priceId`,
                    AccountProductCheckoutSuccess: `${APP_ROOT_PATH}modal/checkout-success/user/:accountId`,
                    PremiumAccountOnly: `${APP_ROOT_PATH}premium-account-only/:userId/:feature`,
                }
            },
            Public: {
                screens: {
                    PublicStack: {
                        screens: {
                            Book: `${APP_ROOT_PATH}book/:bookId`,
                            Group: `${APP_ROOT_PATH}group/:groupSlug/:groupId`,
                            GroupBook: `${APP_ROOT_PATH}group/:groupSlug/:groupId/book/:bookId`,
                            User: `${APP_ROOT_PATH}user/:userId`,
                            Shelf: `${APP_ROOT_PATH}shelf/:shelfId`,
                            List: `${APP_ROOT_PATH}list/:listId`,
                            GroupCollection: `${APP_ROOT_PATH}group/:groupSlug/:groupId/collection/:groupCollectionId`
                        }
                    }
                }
            },
            NotFound: '*',
        },
    },
    getStateFromPath(path, config) {
        const parsedUrl = Linking.parse(path);
        if(isNative() && /^[sblguc]\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
            const shortId = parsedUrl.path.split('/')[1];
            const params = (() => {
                if(path.indexOf('?') > -1) return path.split('?').pop();
                return '';
            })();
            if(/^b\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
                path = `/${APP_ROOT_PATH}home/book/${shortId}`
            }
            if(/^u\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
                path = `/${APP_ROOT_PATH}home/user/${shortId}`
            }
            if(/^g\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
                path = `/${APP_ROOT_PATH}home/group/slug/${shortId}`
            }
            if(/^s\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
                path = `/${APP_ROOT_PATH}home/user/shelf/${shortId}`
            }
            if(/^l\/[A-Za-z0-9_-]{5}/.test(parsedUrl.path)) {
                path = `/${APP_ROOT_PATH}home/user/list/${shortId}`
            }
            if(params) path += '?' + params;
        }
        let stateFromPath = getStateFromPath(path, config);
        const { loggedUserId } = store.getState();
        const rootRouteName = stateFromPath?.routes[0]?.name;
        let { routeName, routeParams } = getRouteDetailsFromStateFromPath(stateFromPath);
        if(!routeName && rootRouteName) routeName = rootRouteName;
        const VALID_ACTION_KEYS = Object.keys(MODALS_OPENABLE_BY_ACTION_URL_PARAM);
        const pathHasValidAction = (() => {
            if(path.match(/\??&?action=/)) {
                const matchedValue = path.match(/action=([a-zA-Z]+)/);
                if(matchedValue) {
                    for(let key of VALID_ACTION_KEYS) {
                        if(matchedValue[1] === key) return true;
                    }
                }
            }
            return false;
        })();
        const mustBeAuthenticated = (!loggedUserId && !PUBLIC_ROUTE_NAMES.includes(routeName)) || (!loggedUserId && PUBLIC_ROUTE_NAMES.includes(routeName) && pathHasValidAction);
        if(rootRouteName && !['Auth', 'NotFound'].includes(rootRouteName) && (mustBeAuthenticated || rootRouteName == 'Modal')) {
            const basePath = pathHasValidAction ? `/${APP_ROOT_PATH}signin` : '/';
            stateFromPath = getStateFromPath(basePath, config);
            store.dispatch(setInitialLoadPath(path));
        }
        const canonicalPath = getCanonicalPath(routeName, routeParams, path, !!loggedUserId);

        if(canonicalPath != path) {
            stateFromPath = getStateFromPath(canonicalPath, config);
        }
        return stateFromPath;
    },
};