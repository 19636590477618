import { MAIN_COLOR_SUBTLE, SECONDARY_COLOR_SUBTLE } from '../constants';
import * as Modals from './ModalComponents'; 

export const ModalConfig = {
    Profile: {
        component: Modals.Profile,
        settings: {
            tBase: 'screens.profile',
            scrollContainerStyle: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    UpdateProfile: {
        component: Modals.UpdateProfile,
        settings: {
            tBase: 'screens.updateProfile',
            showActionBtns: true
        }
    },
    Account: {
        component: Modals.Account,
        settings: {
            tBase: 'screens.account',
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    ChangePassword: {
        component: Modals.ChangePassword,
        settings: {
            tBase: 'screens.changePassword',
            showActionBtns: true,
            saveButtonLabel: 'forms.changePassword.submit',
        }
    },
    Terms: {
        component: Modals.Terms,
        settings: {
            tBase: 'screens.terms',
        }
    },
    Settings: {
        component: Modals.Settings,
        settings: { 
            tBase: 'screens.settings',
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    Book: {
        component: Modals.Book,
        settings: {
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    AddBook: {
        component: Modals.AddBook,
        settings: {
            tBase: 'screens.addBook',
            showActionBtns: true,
            saveButtonLabel: 'screens.addBook.submit',
        }
    },
    AddBookSearch: {
        component: Modals.AddBookSearch,
        settings: {
            tBase: 'screens.addBookSearch',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
            useInsetBottom: false,
        }
    },
    UpdateBook: {
        component: Modals.UpdateBook,
        settings: {
            tBase: 'screens.updateBook',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateBook.submit',
        }
    },
    Scan: {
        component: Modals.Scan,
        settings: {
            tBase: 'screens.scan',
            scrollContainerStyle: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
            useInsetBottom: false,
            scrollEnabled: false
        }
    },
    AddShelf: {
        component: Modals.AddShelf,
        settings: {
            tBase: 'screens.addShelf',
            showActionBtns: true,
            saveButtonLabel: 'screens.addShelf.submit',
        }
    },
    UpdateShelf: {
        component: Modals.UpdateShelf,
        settings: {
            tBase: 'screens.updateShelf',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateShelf.submit',
        }
    },
    AddList: {
        component: Modals.AddList,
        settings: {
            tBase: 'screens.addList',
            showActionBtns: true,
            saveButtonLabel: 'screens.addList.submit',
        }
    },
    UpdateList: {
        component: Modals.UpdateList,
        settings: {
            tBase: 'screens.updateList',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateList.submit',
        }
    },
    TakePhoto: {
        component: Modals.TakePhoto,
        settings: {
            tBase: 'screens.takePhoto',
            scrollContainerStyle: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
            useInsetBottom: false,
            scrollEnabled: false
        }
    },
    Lend: {
        component: Modals.Lend,
        settings: {
            tBase: 'screens.lend',
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    BookInLists: {
        component: Modals.BookInLists,
        settings: {
            tBase: 'screens.bookInLists',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateList.submit',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    FollowRequests: {
        component: Modals.FollowRequests,
        settings: {
            tBase: 'screens.followRequests',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    User: {
        component: Modals.User,
        settings: {
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            scrollEnabled: false,
            useInsetBottom: false,
        }
    },
    Users: {
        component: Modals.Users,
        settings: {
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            scrollEnabled: false,
            useInsetBottom: false,
        }
    },
    InviteUsers: {
        component: Modals.InviteUsers,
        settings: {
            tBase: 'screens.inviteUsers',
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    Followers: {
        component: Modals.Followers,
        settings: {
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            scrollEnabled: false,
            useInsetBottom: false,
        }
    },
    Following: {
        component: Modals.Following,
        settings: {
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            scrollEnabled: false,
            useInsetBottom: false,
        }
    },
    Discussions: {
        component: Modals.Discussions,
        settings: {
            tBase: 'screens.discussions',
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
        }
    },
    Discussion: {
        component: Modals.Discussion,
        settings: {
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
            },
        }
    },
    Contact: {
        component: Modals.Contact,
        settings: {
            tBase: 'screens.contact',
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    Share: {
        component: Modals.Share,
        settings: {
            tBase: 'screens.share',
            showActionBtns: false,
            scrollContainerStyle: {
                paddingTop: 10
            }
        }
    },
    ChooseAccountProduct: {
        component: Modals.ChooseAccountProduct,
        settings: {
            tBase: 'screens.chooseAccountProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    AccountProduct: {
        component: Modals.AccountProduct,
        settings: {
            tBase: 'screens.accountProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: MAIN_COLOR_SUBTLE,
                paddingBottom: 0,
                paddingTop: 0
            },
            useInsetBottom: false
        }
    },
    PrePaymentAccountProduct: {
        component: Modals.PrePaymentAccountProduct,
        settings: {
            tBase: 'screens.prePaymentAccountProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: '#fdfdfd',
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    AccountProductCheckoutSuccess: {
        component: Modals.AccountProductCheckoutSuccess,
        settings: {
            tBase: 'screens.accountProductCheckoutSuccess',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: '#fdfdfd',
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    PremiumAccountOnly: {
        component: Modals.PremiumAccountOnly,
        settings: {
            tBase: 'screens.premiumAccountOnly',
            showActionBtns: false,
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    Group: {
        component: Modals.Group,
        settings: {
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            },
            scrollEnabled: false,
            useInsetBottom: false,
        }
    },
    GroupBook: {
        component: Modals.Book,
        settings: {
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    ChooseGroupProduct: {
        component: Modals.ChooseGroupProduct,
        settings: {
            tBase: 'screens.chooseGroupProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    GroupProduct: {
        component: Modals.GroupProduct,
        settings: {
            tBase: 'screens.groupProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: MAIN_COLOR_SUBTLE,
                paddingBottom: 0,
                paddingTop: 0
            },
            useInsetBottom: false
        }
    },
    GroupProductPro: {
        component: Modals.GroupProductPro,
        settings: {
            tBase: 'screens.groupProductPro',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: SECONDARY_COLOR_SUBTLE,
                paddingBottom: 0,
                paddingTop: 0
            },
            useInsetBottom: false
        }
    },
    AddGroup: {
        component: Modals.AddGroup,
        settings: {
            tBase: 'screens.addGroup',
            showActionBtns: true,
            saveButtonLabel: 'screens.addGroup.submit',
        }
    },
    PrePaymentGroupProduct: {
        component: Modals.PrePaymentGroupProduct,
        settings: {
            tBase: 'screens.prePayementGroupProduct',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: '#fdfdfd',
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    GroupProductCheckoutSuccess: {
        component: Modals.GroupProductCheckoutSuccess,
        settings: {
            tBase: 'screens.groupProductCheckoutSuccess',
            scrollEnabled: false,
            scrollContainerStyle: {
                flex: 1,
                backgroundColor: '#fdfdfd',
                paddingBottom: 0
            },
            useInsetBottom: false
        }
    },
    UpdateGroup: {
        component: Modals.UpdateGroup,
        settings: {
            tBase: 'screens.updateGroup',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateGroup.submit',
        }
    },
    BooksInGroup: {
        component: Modals.BooksInGroup,
        settings: {
            tBase: 'screens.booksInGroup',
            showActionBtns: true,
            scrollEnabled: false,
            saveButtonLabel: 'screens.booksInGroup.submit',
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            }
        }
    },
    UpdateShelfShareBooksGroup: {
        component: Modals.UpdateShelfShareBooksGroup,
        settings: {
            tBase: 'screens.updateShelfShareBooksGroup',
            showActionBtns: true,
            scrollEnabled: true,
            saveButtonLabel: 'screens.updateShelfShareBooksGroup.submit'
        }
    },
    InviteToJoinGroup: {
        component: Modals.InviteToJoinGroup,
        settings: {
            tBase: 'screens.inviteToJoinGroup',
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingBottom: 0,
                paddingLeft: 0,
                paddingRight: 0,
            }
        }
    },
    GroupMembers: {
        component: Modals.GroupMembers,
        settings: {
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    GroupShare: {
        component: Modals.GroupShare,
        settings: {
            tBase: 'screens.shareGroup',
            showActionBtns: false,
            scrollContainerStyle: {
                paddingTop: 10
            }
        }
    },
    AddBookReview: {
        component: Modals.AddBookReview,
        settings: {
            tBase: 'screens.addBookReview',
            saveButtonLabel: 'screens.addBookReview.submit',
            showActionBtns: true,
        }
    },
    UpdateBookReview: {
        component: Modals.UpdateBookReview,
        settings: {
            tBase: 'screens.updateBookReview',
            saveButtonLabel: 'screens.updateBookReview.submit',
            showActionBtns: true,
        }
    },
    Search: {
        component: Modals.Search,
        settings: {
            tBase: 'screens.search',
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    Shelf: {
        component: Modals.Shelf,
        settings: {
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    List: {
        component: Modals.List,
        settings: {
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    BookProviderResult: {
        component: Modals.BookProviderResult,
        settings: {
            tBase: 'screens.bookProviderResult',
            showActionBtns: true,
            saveButtonLabel: 'screens.bookProviderResult.submit',
            scrollContainerStyle: {
                paddingTop: 0
            }
        }
    },
    GroupCollection: {
        component: Modals.GroupCollection,
        settings: {
            scrollEnabled: false,
            useInsetBottom: false,
            scrollContainerStyle: {
                flex: 1,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0,
                paddingTop: 0
            }
        }
    },
    AddGroupCollection: {
        component: Modals.AddGroupCollection,
        settings: {
            tBase: 'screens.addGroupCollection',
            saveButtonLabel: 'screens.addGroupCollection.submit',
            showActionBtns: true,
        }
    },
    UpdateGroupCollection: {
        component: Modals.UpdateGroupCollection,
        settings: {
            tBase: 'screens.updateGroupCollection',
            saveButtonLabel: 'screens.updateGroupCollection.submit',
            showActionBtns: true,
        }
    },
    BooksInGroupCollection: {
        component: Modals.BooksInGroupCollection,
        settings: {
            tBase: 'screens.booksInGroupCollection',
            showActionBtns: true,
            scrollEnabled: false,
            saveButtonLabel: 'screens.booksInGroupCollection.submit',
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            }
        }
    },
    Subscriptions: {
        component: Modals.Subscriptions,
        settings: {
            tBase: 'screens.subscriptions'
        }
    },
    AskForFeedback: {
        component: Modals.AskForFeedback,
        settings: {
            tBase: 'screens.askForFeedback',
            saveButtonLabel: 'screens.askForFeedback.submit',
            showActionBtns: true,
        }
    },
    PremiumGroupOnly: {
        component: Modals.PremiumGroupOnly,
        settings: {
            tBase: 'screens.premiumGroupOnly',
            showActionBtns: false,
            scrollContainerStyle: {
                paddingLeft: 0,
                paddingRight: 0
            }
        }
    },
    DeviceContacts: {
        component: Modals.DeviceContacts,
        settings: {
            tBase: 'screens.deviceContacts',
            saveButtonLabel: 'screens.deviceContacts.submit',
            showActionBtns: true,
            scrollEnabled: false,
            scrollContainerStyle: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            }
        }
    },
    AddUserKeyword: {
        component: Modals.AddUserKeyword,
        settings: {
            tBase: 'screens.addUserKeyword',
            showActionBtns: true,
            saveButtonLabel: 'screens.addUserKeyword.submit',
        }
    },
    UpdateUserKeyword: {
        component: Modals.UpdateUserKeyword,
        settings: {
            tBase: 'screens.updateUserKeyword',
            showActionBtns: true,
            saveButtonLabel: 'screens.updateUserKeyword.submit',
        }
    },
    FilterBooks: {
        component: Modals.FilterBooks,
        settings: {
            tBase: 'screens.filterBooks',
            scrollEnabled: false,
            saveButtonLabel: 'screens.filterBooks.submit',
            scrollContainerStyle: {
                paddingTop: 0,
                paddingLeft: 0,
                paddingRight: 0,
                paddingBottom: 0
            }
        }
    }
}